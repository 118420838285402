
import { reactive, defineComponent, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getResetVerificationCode, login } from "@/api/web";
import { Toast } from "vant";

export default defineComponent({
  setup() {
    const state = reactive({
      deviceId: "",
      validCode: "",
    });

    const isSubmit = reactive({
      flag: true,
      codeText: "发送验证码",
    });

    const formRef = ref();
    const vcodeRef = ref();

    const store = useStore();

    const handleSendVcode = async () => {
      store.commit('setToken', null);
      const form = unref(formRef);
      if (!form) return;
      if (!isSubmit.flag) {
        return;
      }
      try {
        await form.validate("mobile");
      } catch (err) {
        Toast({
          type: "fail",
          message: (err as Error).message,
        });
        return;
      }

      const res = await getResetVerificationCode(state.deviceId);
      // 发送成功后调用倒计时
      if (res && (res as any).resp_code === 0) {
        vcodeRef.value.focus();
        Toast({
          type: "success",
          message: "发送成功",
        });
        intervalEvent();
      }
    };

    const intervalEvent = () => {
      isSubmit.flag = false;
      let count = 60;
      let time = setInterval(() => {
        count--;
        isSubmit.codeText = count + "s";
        if (count == 1) {
          clearInterval(time);
          isSubmit.flag = true;
          isSubmit.codeText = "发送验证码";
        }
      }, 1000);
    };

    const router = useRouter();
    const onSubmit = async () => {
      const res = await login({ ...state });
      if ((res as any).resp_code === 0) {
        const { access_token, token_type, expires_in, refresh_token } = (res as any).datas;
        store.commit('setToken', { access_token, token_type, expires_in, refresh_token });
        router.push({
          name: "reset",
          params: { globalAccount: state.deviceId },
        });
      }
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validMobile = (value: string) => {
      return !!value.match(/^1[3-9]\d{9}$/);
    };

    return {
      state,
      isSubmit,
      formRef,
      vcodeRef,
      handleSendVcode,
      intervalEvent,
      onSubmit,
      validFailed,
      validMobile,
    };
  },
});
