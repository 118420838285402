
<template>
  <div class="retrieve">
    <div class="head">
      <div class="title">找回密码</div>
      <div class="subTitle">请输入手机号找回密码</div>
    </div>
    <van-form
      class="vanForm"
      @submit="onSubmit"
      @failed="validFailed"
      ref="formRef"
      :show-error="false"
      :show-error-message="false"
      :validate-first="true"
    >
      <van-field
        type="tel"
        icon-prefix="rmy-icon"
        left-icon="shoujihao"
        clearable
        v-model="state.deviceId"
        name="mobile"
        placeholder="请输入手机号"
        :rules="[
          { required: true, message: '请填写手机号' },
          {
            required: true,
            validator: validMobile,
            message: '请输入正确格式的手机号',
          },
        ]"
      />
      <van-field
        icon-prefix="rmy-icon"
        left-icon="zu467"
        ref="vcodeRef"
        clearable
        v-model="state.validCode"
        maxlength="4"
        type="number"
        name="vcode"
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <div @click="handleSendVcode" style="color: #348bff">
            {{ isSubmit.codeText }}
          </div>
        </template>
      </van-field>

      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit"
          >下一步</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getResetVerificationCode, login } from "@/api/web";
import { Toast } from "vant";

export default defineComponent({
  setup() {
    const state = reactive({
      deviceId: "",
      validCode: "",
    });

    const isSubmit = reactive({
      flag: true,
      codeText: "发送验证码",
    });

    const formRef = ref();
    const vcodeRef = ref();

    const store = useStore();

    const handleSendVcode = async () => {
      store.commit('setToken', null);
      const form = unref(formRef);
      if (!form) return;
      if (!isSubmit.flag) {
        return;
      }
      try {
        await form.validate("mobile");
      } catch (err) {
        Toast({
          type: "fail",
          message: (err as Error).message,
        });
        return;
      }

      const res = await getResetVerificationCode(state.deviceId);
      // 发送成功后调用倒计时
      if (res && (res as any).resp_code === 0) {
        vcodeRef.value.focus();
        Toast({
          type: "success",
          message: "发送成功",
        });
        intervalEvent();
      }
    };

    const intervalEvent = () => {
      isSubmit.flag = false;
      let count = 60;
      let time = setInterval(() => {
        count--;
        isSubmit.codeText = count + "s";
        if (count == 1) {
          clearInterval(time);
          isSubmit.flag = true;
          isSubmit.codeText = "发送验证码";
        }
      }, 1000);
    };

    const router = useRouter();
    const onSubmit = async () => {
      const res = await login({ ...state });
      if ((res as any).resp_code === 0) {
        const { access_token, token_type, expires_in, refresh_token } = (res as any).datas;
        store.commit('setToken', { access_token, token_type, expires_in, refresh_token });
        router.push({
          name: "reset",
          params: { globalAccount: state.deviceId },
        });
      }
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validMobile = (value: string) => {
      return !!value.match(/^1[3-9]\d{9}$/);
    };

    return {
      state,
      isSubmit,
      formRef,
      vcodeRef,
      handleSendVcode,
      intervalEvent,
      onSubmit,
      validFailed,
      validMobile,
    };
  },
});
</script>

<style lang="scss" scoped>
.retrieve {
  background: #fff;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  .head {
    height: 25%;
    padding-left: 34px;
    padding-top: 118px;
    .title {
      font-size: 28px;
      font-weight: bold;
      line-height: 40px;
      color: #363737;
    }
    .subTitle {
      font-size: 16px;
      line-height: 22px;
      color: #aaaaaa;
    }
  }
  .vanForm {
    padding: 0 16px;
  }
}
</style>
